import React, { useState, useEffect } from 'react';
import './ThemeDialog.css'; // 确保样式文件被正确引入

function ThemeDialog({ show, onClose, themes, onSelect, username }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState(''); // 搜索框的状态
  const [authorizationCounts, setAuthorizationCounts] = useState({}); // 用于存储每个主题的授权数量

  useEffect(() => {
    // 仅当对话框显示时才获取每个主题的授权数量
    if (show) {
      const fetchAuthorizationCounts = async () => {
        const counts = {};
        for (const theme of themes) {
          // 构造主题格式为 "主题名-作者名.wt"
          const themeFormatted = `${theme.themeName}-${theme.author}`;

          try {
            const url = `${apiUrl}/api/themeAuthorizationCount?username=${username}&theme=${encodeURIComponent(themeFormatted)}`;
            console.log(`请求 URL: ${url}`); // 输出请求 URL 用于调试

            const response = await fetch(url);
            if (response.ok) {
              const data = await response.json();
              console.log('响应数据:', data); // 输出响应数据用于调试
              counts[theme.themeName] = data.authorizationCount;
            } else {
              console.error(`获取主题 ${themeFormatted} 授权数量时出错: 状态码 ${response.status}`);
            }
          } catch (error) {
            console.error(`获取主题 ${themeFormatted} 授权数量时出错:`, error);
          }
        }
        setAuthorizationCounts(counts);
      };

      fetchAuthorizationCounts();
    }
  }, [show, themes, username]);

  if (!show) {
    return null;
  }

  // 根据搜索词过滤主题列表
  const filteredThemes = themes.filter((theme) =>
    theme.themeName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="theme-dialog-overlay" onClick={onClose}>
      <div className="theme-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="dialog-content">
          <h2>选择主题</h2>
          {/* 搜索框 */}
          <input
            type="text"
            placeholder="搜索主题"
            className="search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // 更新搜索框的状态
          />
          <ul className="theme-list">
            {filteredThemes.map((theme, index) => (
              <li
                key={index}
                onClick={() => onSelect(`${theme.themeName}-${theme.author}`)}
              >
                <div className="theme-item">
                  <span className="theme-name">{theme.themeName}</span>
                  <div className="theme-details">
                      <span className="theme-author">作者：{theme.author.replace('.wt', '')}</span>
                      <span className="authorization-count">授权数量：{authorizationCounts[theme.themeName] || 0}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ThemeDialog;
