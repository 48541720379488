// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.png';
import ThemeDialog from './themeDialog';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard({ username, empowerCount: initialEmpowerCount }) {
  const logoUrl = `https://oss.hitale.top/icon/${username}.jpg`;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [showDialog, setShowDialog] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [wechatIds, setWechatIds] = useState('');
  const [themes, setThemes] = useState([]);
  const [empowerCount, setEmpowerCount] = useState(initialEmpowerCount);

  const handleThemeClick = () => {
    setShowDialog(true);
  };

  const handleThemeSelect = (theme) => {
    setSelectedTheme(theme);
    setShowDialog(false);
  };

  useEffect(() => {
    // 获取用户主题列表
    fetch(`${apiUrl}/api/themes/${username}`)
      .then((response) => response.json())
      .then((data) => {
        const formattedThemes = data.themes.map((theme) => {
          const [themeName, author] = theme.split('-');
          return { themeName, author };
        });
        setThemes(formattedThemes);
      })
      .catch((error) => {
        console.error('获取主题列表失败:', error);
      });
  }, [username]);

  const handleAuthorizeClick = async () => {
    const wechatIdList = wechatIds.split(',').filter((id) => id.trim() !== '');

    if (selectedTheme === '' || wechatIdList.length === 0) {
      toast.error('请选择主题并输入至少一个微信号');
      return;
    }

    let failedAuthorizations = 0;

    for (const wechatId of wechatIdList) {
      try {
        const response = await fetch(`${apiUrl}/api/authorize`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ selectedTheme, wechatId, username }),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success(`授权成功`);
        } else {
          failedAuthorizations++;
          toast.error(`${data.message}`);
        }
      } catch (error) {
        failedAuthorizations++;
        console.error('请求出错:', error);
        toast.error(`授权请求失败: ${wechatId}`);
      }
    }

    if (failedAuthorizations === 0) {
      try {
        const userResponse = await fetch(`${apiUrl}/api/userInfo?username=${username}`);
        const userData = await userResponse.json();

        if (userResponse.ok) {
          setEmpowerCount(userData.Empower);
        } else {
          toast.error('获取授权次数失败');
        }
      } catch (error) {
        console.error('获取授权次数请求出错:', error);
        toast.error('获取授权次数请求失败');
      }
    }

    setWechatIds('');
  };

  const handleDeleteAuthorizationClick = async () => {
    const wechatIdList = wechatIds.split(',').filter((id) => id.trim() !== '');

    if (selectedTheme === '' || wechatIdList.length === 0) {
      toast.error('请选择主题并输入至少一个微信号');
      return;
    }

    let failedDeletions = 0;

    for (const wechatId of wechatIdList) {
      try {
        const response = await fetch(`${apiUrl}/api/deleteAuthorization`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ selectedTheme, wechatId, username }),
        });

        const data = await response.json();

        if (response.ok) {
          toast.success(`删除授权成功`);
        } else {
          failedDeletions++;
          toast.error(`${data.message}`);
        }
      } catch (error) {
        failedDeletions++;
        console.error('请求出错:', error);
        toast.error(`删除授权请求失败: ${wechatId}`);
      }
    }

    if (failedDeletions === 0) {
      try {
        const userResponse = await fetch(`${apiUrl}/api/userInfo?username=${username}`);
        const userData = await userResponse.json();

        if (userResponse.ok) {
          setEmpowerCount(userData.Empower);
        } else {
          toast.error('获取授权次数失败');
        }
      } catch (error) {
        console.error('获取授权次数请求出错:', error);
        toast.error('获取授权次数请求失败');
      }
    }

    setWechatIds('');
  };

  return (
    <div className="Dashboard">
      <header className="App-header">
        <img src={logoUrl} className="Header-logos" alt="logo" />
        <div className="user-info">
          <span className="login-status">{username}</span>
          <span className="authorization-info">授权剩余：{empowerCount}</span>
        </div>
      </header>
      <div className="logo-container">
        <img src={logo} className="App-logos" alt="logo" />
        <h1 className="App-title">WeTheme授权网</h1>
      </div>
      <div className="form-container">
        <input
          type="text"
          id="themeName"
          className="input-field"
          placeholder="请选择主题"
          value={selectedTheme}
          onClick={handleThemeClick}
          readOnly
        />
        <input
          type="text"
          placeholder="请输入微信号"
          className="input-field"
          value={wechatIds}
          onChange={(e) => setWechatIds(e.target.value)}
        />
        <div className="button-container">

          <button className="login-button" onClick={handleDeleteAuthorizationClick}>
            删除授权
          </button>
          <button className="login-button" onClick={handleAuthorizeClick}>
            确认授权
          </button>
          
        </div>
      </div>
      <ThemeDialog
        show={showDialog}
        onClose={() => setShowDialog(false)}
        themes={themes}
        onSelect={handleThemeSelect}
        username={username}
      />
    </div>
  );
}

export default Dashboard;
